<template>
  <div id="overview-page">
    <b-overlay :show="loading">
      <custom-breadcrumb class="mt-2" :items="breadcrumbs" />

      <div class="custom-container">
        <h4>Overview</h4>
        <h6 class="text-muted"> Patient ID : {{ patientRef }} </h6>
        <hr />

        <b-tabs>
          <b-tab
            v-for="(item, idx) in tabs"
            :key="idx"
            :id="item.page"
            :title="item.label"
            :active="item.page === selectedTab"
            @click="selectTab(item.page)"
          />

          <br />
          <component 
            :is="selectedTab" 
            :loading="loading" 
            :content="assessment.content"
            :options="assessment.options"
            :can-manage-patient="canManagePatient"
          />
        </b-tabs>

        <div class="text-center buttons mt-3">
          <button
            v-if="canManagePatient && $route.params.tab === 'patientOverview'"
            class="btn btn-sm btn-pompe-primary mr-2" @click.prevent="newVisit"
          >
            New Visit
          </button>
          <button
            v-if="canManagePatient && $route.params.tab === 'assessmentOverview'"
            class="btn btn-sm btn-pompe-primary mr-2" @click.prevent="newAssessment"
          >
            Start Assessment
          </button>
          <button class="btn btn-sm btn-secondary" @click="$router.push('/')">
            Go Back
          </button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import patientOverview from "./patientOverview/Index.vue";
import assessmentOverview from "./Assessment/Index";
import evaluationDownload from '../Evaluation/Index.vue';

import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

import { getPatientRef } from '../../../helper/getPatientRef';

import moment from "moment";

export default {
  name: 'OverviewIndex',
  components: {
    patientOverview,
    assessmentOverview,
    evaluationDownload
  },
  data: () => ({
    breadcrumbs: [
      { 
        key: 'home', 
        text: 'Home',
        action: () => window.location.href = '/web',
      },
      {
        key: 'overview',
        text: 'Overview',
        active: true,
      }
    ],
    loading: false,
    notification: {
      type: "",
      message: "",
      isActive: false,
    },
    assessment: {
      content: {},
      options: []
    },
    patientRef: '',
    canManagePatient: false,
  }),
  computed: {
    ...mapState({
      selectedTab: (state) => state.overviewPage.selectedTab,
      content: (state) => state.overviewPage.content,
      options: (state) => state.overviewPage.options,
    }),
    ...mapGetters({
      userData: 'general/getUserData',
      patientData: 'creation/getPatientData',
      tabs: 'tabs/getTabs'
    }),
  },
  watch: {
    userData: {
      handler(val) {
        this.checkAccess(val);
      },
      deep: true,
      immediate: true,
    }
  },
  async mounted() {
    this.assignTabs([]);

    const { data } = await this.getPatientRef(this.$route.params.id);
    this.patientRef = data.ref || '-';

    this.setSelectedTab(this.$route.params.tab);
    if (this.$route.params.tab === 'assessmentOverview') {
      this.loading = true;
      this.startAssessmentOverviewProcess(this.$route.params.id);
    } else {
      this.getData();
    }
  },
  methods: {
    getPatientRef,
    ...mapMutations({
      setSelectedTab: "overviewPage/setSelectedTab",
      setData: "overviewPage/setData",
      setVisitData: "overviewPage/setVisitData",
      setOptions: "overviewPage/setOptions",
      setTabs: "overviewPage/setTabs",
    }),
    ...mapActions({
      assignTabs: 'tabs/assignTabs',
    }),
    async selectTab(tab) {
      if (this.selectedOverviewTab !== tab) {
        this.setSelectedTab(tab);
        await this.$router.push({
          name: "patientOverview",
          params: { tab: tab, id: this.$route.params.id },
        });
      }

      if (tab === 'assessmentOverview') {
        this.loading = true;
        this.startAssessmentOverviewProcess(this.$route.params.id);
      } else {
        this.getData();
      }
    },
    handleAssessmentOverviewStartSuccess(response) {
      this.assessment.content = response.data.content;
      this.assessment.options = response.data.options;

      this.assessment.content.assessmentOverview.primaryEndpoints.map(el => {
        el.dateOfVisit = this.formatDateNormal(el.dateOfVisit);
      })

      this.assessment.content.assessmentOverview.otherEndpoints.map(el => {
        el.dateOfVisit = this.formatDateNormal(el.dateOfVisit);
      })

      // this.assignTabs(response.data.nav)
      this.assignTabs([
        { label: 'Patient Overview', page: 'patientOverview' },
        { label: 'Assessment Diagnosis', page: 'assessmentOverview' },
        { label: 'Evaluation Download', page: 'evaluationDownload' },
      ])
      this.loading = false;
    },
    changeDateFormat(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    handleAssessmentOverviewStartFailed() {
      this.setSelectedTab('patientOverview');
      this.$router.push({
        name: 'patientOverview',
        params: {
          tab: 'patientOverview',
          id: this.$route.params.id
        }
      })

      this.loading = false;
    },
    getData() {
      this.loading = true;

      this.$requestService
        .post(
          this.$apiEndpoints.startProcessOverview +
            `?businessKey=${this.$route.params.id}`,
          {},
          (response) => {
            this.setData(response.data.content);
            this.setOptions(response.data.options);
            // this.assignTabs(response.data.nav);
             this.assignTabs([
        { label: 'Patient Overview', page: 'patientOverview' },
        { label: 'Assessment Diagnosis', page: 'assessmentOverview' },
        { label: 'Evaluation Download', page: 'evaluationDownload' },
      ])
          },
          (e) => console.log(e)
        )
        .then(() => {
          this.loading = false;
        });
    },
    newVisit() {
      this.loading = true;

      this.$requestService
        .post(
          this.$apiEndpoints.startProcessNewVisit +
            `?businessKey=${this.$route.params.id}`,
          {},
          this.handleNewVisitSuccess,
          e => console.error(e)
        )
        .then(() => {
          this.loading = false;
        });
    },
    handleNewVisitSuccess(response) {
      this.$router.push({
        name: 'addPatient',
        params: {
          tab: response.data.page,
          id: this.$route.params.id,
          state: 'overview-visit'
        }
      })
    },
    newAssessment() {
      this.loading = true;

      this.$requestService
        .post(
          this.$apiEndpoints.startProcessAssessment +
            `?businessKey=${this.$route.params.id}`,
          {},
          this.handleAssessmentSuccess,
          e => console.error(e)
        )
        .then(() => {
          this.loading = false;
        });
    },
    handleAssessmentSuccess(response) {
       this.$router.push({
        name: 'assessmentData',
        params: {
          tab: response.data.page,
          id: this.$route.params.id,
          state: 'overview-assessment'
        }
      })
    },
    async checkAccess({ role, id }) {
      await this.setPatientData(this.$route.params.id);
      const { physicianId } = this.patientData;
      this.canManagePatient = (
        (role === "PHYSICIAN" && id === physicianId)
        || role === 'ADMIN' || role === 'MANAGER'
      );
    },
  },
};
</script>

<style lang="scss">
@import "./Index.scss";
</style>
