<template>
  <div id="medical-concomitant">
    <collapsible-container
      id="pompeTherapy"
      title="Pompe Therapy"
      :isToggled="isToggled"
      @toggle-collapse="isToggled = !isToggled"
    >
      <div v-if="data && data.length">
         <div v-for="(item, idx) in data" :key="idx">
            <!-- Pompe Therapy Status -->
            <detail-text 
              label="Pompe Therapy Status" 
              :text="pompeStatus[item.pompeStatus]"
            />

            <!-- Start of treatment -->
            <detail-text 
              v-if="item.pompeStatus === 'switchTherapy'"
              label="Date of Switch Therapy" 
              :text="formatDateNormal(item.dateSwitchTreatment)"
            />

            <!-- Start of treatment -->
            <detail-text 
              label="Date of Beginning Treatment" 
              :text="formatDateNormal(item.dateBeginningTreatment)"
            />

            <!-- Drug -->
            <detail-text 
              label="Drug" 
              :text="drugs[item.drug]"
            />

            <!-- Dose -->
            <detail-text 
              label="Dose (mg/kg)" 
              :text="item.dose ? item.dose.toString() : '-'"
            />

            <!-- Dose Frequency -->
            <detail-text 
              label="Dose Frequency" 
              :text="item.doseFrequency"
            />

            <!-- Infusion Duration -->
            <detail-text 
              label="Infusion Duration" 
              :text="`${item.infusionDurationAverageHours === 0 ? 0 : item.infusionDurationAverageHours || '-'} h ${item.infusionDurationAverageMinutes === 0 ? 0 : item.infusionDurationAverageMinutes || '-'} m`"
            />
         </div>
      </div>

      <div v-else>
        <span class="h6 text-muted"> No Pompe Therapy Data </span>
      </div>
    </collapsible-container>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    isToggled: false,
    pompeStatus: {
      neverOnPompeTherapy: 'Never on Pompe Therapy',
      switchTherapy: 'Switch therapy',
      drugsTemporarilyStopped: 'Drugs temporarily stopped',
      drugsFullyStopped: 'Drugs fully stopped',
      ongoing: 'On Going',
      unknown: 'Unknown'
    },
    drugs: {
      alglucosidaseAlfa: 'Alglucosidase alfa',
      avalglucosidaseAlfa: 'Avalglucosidase alfa',
      investigationalDrug: 'Investigational drug',
      unknown: 'Unknown'
    },
  }),
};
</script>

<style></style>
