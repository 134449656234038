<template>
  <div id="enzyme-overview">
    <h6> {{ capitalizeFirstLetter(sectionType) }} Enzyme Assay </h6>
    <detail-text
      class="mt-2"
      label="Sample Date"
      :text="data[`${sectionType}SampleDate`] ? formatDateNormal(data[`${sectionType}SampleDate`]) : null"
    />
    
    <detail-text
      label="Assay Method"
      :text="data[`${sectionType}AssayMethod`]"
    />

    <detail-text
      v-if="data[`${sectionType}EnzymeActivity`]"
      label="Enzyme Activity"
      :text="data[`${sectionType}EnzymeActivity`].toString() + ` ` + data[`${sectionType}EnzymeActivityUnit`]"
    />

    <detail-text
      v-if="data[`${sectionType}NormalRangeLow`]"
      label="Range Low"
      :text="data[`${sectionType}NormalRangeLow`].toString()"
    />

    <detail-text
      v-if="data[`${sectionType}NormalRangeHigh`]"
      label="Range High"
      :text="data[`${sectionType}NormalRangeHigh`].toString()"
    />

    <detail-text
      v-if="sectionType === 'first'"
      label="Have any patient's siblings been diagnosed with pompe"
      :text="capitalizeFirstLetter(data.patientSiblingsHavePompe)"
    />

    <detail-text
      v-if="sectionType === 'first' && data.patientSiblingsHavePompe === 'yes'"
      label="Number of Siblings Diagnosed"
      :text="data.numberOfSiblingsDiagnosed.toString()"
    />
  </div>
</template>

<script>
export default {
  props: {
    sectionType: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      if (string) return string.charAt(0).toUpperCase() + string.slice(1);
      return '';
    }
  }
}
</script>

<style>

</style>